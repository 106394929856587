import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const fullSiteAllowed = true // change to true when we go live!

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dedication-prayer',
    name: 'Dedication Prayer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dedication-prayer" */ '../views/DedicationPrayer.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/urgency-of-history',
    name: 'The Urgency of History',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "urgency-of-history" */ '../views/UrgencyOfHistory.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/four-crucial-values',
    name: 'Four Crucial Values',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "four-crucial-values" */ '../views/FourCrucialValues.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/seven-spheres',
    name: 'Seven Spheres',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "seven-spheres" */ '../views/SevenSpheres.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/the-prayer-room',
    name: 'The Prayer Room',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "the-prayer-room" */ '../views/ThePrayerRoom.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/upcoming-events',
    name: 'Upcoming Events',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "upcoming-events" */ '../views/UpcomingEvents.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/register',
    name: 'Register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
  },
  { 
    path: '/register/oct2023',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    alias: '/oct2023' 
  },
  {
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/donate',
    name: 'Donate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "donate" */ '../views/Donate.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/donate-complete',
    name: 'Donation Complete',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "donate-complete" */ '../views/DonateComplete.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/privacy-policies',
    name: 'Privacy Policies',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "privacy-policies" */ '../views/PrivacyPolicies.vue')
  },
  {
    path: '/page/:link_name',
    name: 'Page',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "coming-soon" */ '../views/Page.vue'),
    props: true
  },
  {
    path: '/coming-soon',
    name: 'Coming Soon',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "coming-soon" */ '../views/ComingSoon.vue')
  },
  {
    path: '/page-not-found',
    name: 'Page Not Found',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "page-not-found" */ '../views/PageNotFound.vue')
  },
  {
    path: "*",
    component: () => import(/* webpackChunkName: "page-not-found" */ '../views/PageNotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     // this route requires auth, check if fullSiteAllowed
//     if (!fullSiteAllowed) {
//       next({
//         path: '/coming-soon',
//         query: { redirect: to.fullPath }
//       })
//     } else {
//       next()
//     }
//   } else {
//     next() // make sure to always call next()!
//   }
// })
