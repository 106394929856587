<template>
    <div v-if="getAllowFullSite" class="main home">
        <div ref="top-of-home"></div>
        <div :class="['hero-section', { no_content: heroContent.length == 0 }]">
            <div class="hero-video-container">
    			<iframe :src="vimeoURL" frameborder="0" allow="fullscreen" id="player_iframe"></iframe>
                <div class="hero-video-content">
                    <p>The Great Awakening Project<br> is a coalition of leaders working together towards Kingdom advancement through every channel of cultural influence, for reformation and renewal across America and around the world.</p>
                    <div v-if="vimeoPlaying">
                        <font-awesome-icon icon="pause" class="player-icon" @click="pauseVideo()" />
                    </div>
                    <div v-else>
                        <font-awesome-icon icon="play" class="player-icon" @click="playVideo()" />
                    </div>
                </div>
            </div>
            <div v-if="heroContent.length > 0" class="hero-content-container">
                <div v-for="(content, index) in heroContent" class="spaced">
                    <h3 v-if="content.content_type_code == 'ARTICLE'">{{ content.description }}</h3>
                    <h3 v-else>{{ content.content_type }}</h3>
                    <p v-html="content.excerpt"></p>
                    <router-link v-if="content.link_name" :to="'page/' + content.link_name">Read more...</router-link>
                </div>
            </div>
        </div>

        <div class="section-light">
            <div class="section-content vision-section">
                <div>
                    <h1>THE VISION</h1>
                    <p>To awaken the followers of Christ to restore intimacy with God, establish critical cultural transformation and create increasing Kingdom expansion, for generations.</p>
                </div>
                <div>
                    <h1>THE PURPOSE</h1>
                    <p>To establish the Lordship and preeminence of Christ in every sphere of life, including government, economics and business, celebration (arts & entertainment), education, media, family and religion.</p>
                </div>
                <div>
                    <h1>THE MISSION</h1>
                    <p>To be an Ambassador of Christ, who will walk in the fear of the Lord and serve each other with unconditional love, humility, and forgiveness as one body under the cross.</p>
                </div>
            </div>
        </div>

        <div class="section-dark qoute-section-wrapper">
            <div class="section-content quote-section">
                <p>In revival there is no such thing as <span class="h1">OUR</span> calling or purpose...<br>it's <span class="h1">HIS</span> purpose and <span class="h1">OUR</span> cross.</p>
                <hr>
                <p>Wherever we go we are driven to action.<br>In our homes, communities, work places, and in every sphere of society our goal is to make Him known.</p>
                <router-link to="/about"><button class="button-light">Read more about GAP...</button></router-link>
            </div>
        </div>

        <div class="section-light">
            <div class="section-content">
                <div class="unity-section">
                    <div>
                        <h1>Unity</h1>
                        <p>Unity In Christ Jesus</p>
                    </div>
                    <font-awesome-icon icon="chevron-right" class="icon"/>
                    <div>
                        <h1>Awakening</h1>
                        <p>Awakening in the Church</p>
                    </div>
                    <font-awesome-icon icon="chevron-right" class="icon"/>
                    <div>
                        <h1>Revival</h1>
                        <p>Revival Across the Land</p>
                    </div>
                    <font-awesome-icon icon="chevron-right" class="icon"/>
                    <div>
                        <h1>The Gospel</h1>
                        <p>The Gospel Preached to the Ends of the Earth</p>
                    </div>
                </div>
                <router-link to="/four-crucial-values"><button class="button-light">Read more about these four crucial values...</button></router-link>
            </div>
        </div>

        <div class="section-dark prayer-section-wrapper">
            <div class="section-content quote-section">
                <p>&ldquo;At any time I may announce that I will dig up, pull down, and destroy a nation or kingdom; but if that nation I warned turns from its evil, then I’ll relent and not carry out the harm I intended for it.&rdquo;</p>
                <p>&mdash; Jeremiah 18:7-8 (CEB)</p>
                <router-link to="/the-prayer-room"><button class="button-light">Visit the Prayer Room...</button></router-link>
            </div>
        </div>

        <div class="section-light">
            <div class="section-content">
                <h1>Get Involved</h1>
                <p>Join with us as we pray and work in Unity for Awakening, Revival, and The Gospel.</p>
                <subscribe buttonLabel="Subscribe" buttonClass="button-light"/>
            </div>
        </div>

        <div class="section-dark covenant-section-wrapper">
            <div class="section-content covenant-section">
                <p>&ldquo;May this Covenant of Dedication remain to all generations,<br>as long as this earth remains...&rdquo;</p>
                <p>&mdash; Robert Hunt, April 29, 1607</p>
                <router-link to="/dedication-prayer"><button class="button-light">Learn more about America's original dedication...</button></router-link>
            </div>
        </div>

        <div class="section-light">
            <div class="section-content spheres-section">
                <h1>The Seven Spheres Of Influence</h1>
                <p>Spheres of Influence are a strategic framework for impacting and awakening the Church toward repentance and revival.</p>

                <div :class="['seven-spheres-grid', { active: showCaptions }]">

                    <div class="spheres-tile-group family-border" @click="showCaption('family')">
                        <div class="sphere-spacer family-border"></div>
                        <div class="sphere-tile family-border"></div>
                        <div :class="['sphere-label', { active: activeCaption['family'] }]">Family</div>
                        <div :class="['sphere-caption', 'family-border', { active: activeCaption['family'] }]">Do you feel called to preserve and impact God’s vision for the family unit -  and/or to speak and serve the fatherlessness, broken families, the foster care system or adoption processes?</div>
                    </div>

                    <div class="spheres-tile-group education-border" @click="showCaption('education')">
                        <div class="sphere-spacer education-border"></div>
                        <div class="sphere-tile education-border"></div>
                        <div :class="['sphere-label', { active: activeCaption['education'] }]">Education</div>
                        <div :class="['sphere-caption', 'education-border', { active: activeCaption['education'] }]">Are you passionate about preserving truth and history, textbooks and other learning resources, and impacting the education system?</div>
                    </div>

                    <div class="spheres-tile-group government-border" @click="showCaption('government')">
                        <div class="sphere-spacer government-border"></div>
                        <div class="sphere-tile government-border"></div>
                        <div :class="['sphere-label', { active: activeCaption['government'] }]">Government</div>
                        <div :class="['sphere-caption', 'government-border', { active: activeCaption['government'] }]">Has God called you to lawful governance? Do you have interests in local campaigns, the Supreme Court or the National Day of Prayer? Are you passionate about preserving Judeo-Christian values and fighting for our republic?</div>
                    </div>

                    <div class="spheres-tile-group economics-border" @click="showCaption('economics')">
                        <div class="sphere-spacer economics-border"></div>
                        <div class="sphere-tile economics-border"></div>
                        <div :class="['sphere-label', { active: activeCaption['economics'] }]">Business / Economics</div>
                        <div :class="['sphere-caption', 'economics-border', { active: activeCaption['economics'] }]">Has God given you an entrepreneurial spirit or zeal for business, technology, medicine or finances?</div>
                    </div>

                    <div class="spheres-tile-group celebration-border" @click="showCaption('celebration')">
                        <div class="sphere-spacer celebration-border"></div>
                        <div class="sphere-tile celebration-border"></div>
                        <div :class="['sphere-label', { active: activeCaption['celebration'] }]">Celebration <span style="font-size: 0.7em">(arts, entertainment, sports)</span></div>
                        <div :class="['sphere-caption', 'celebration-border', { active: activeCaption['celebration'] }]">Are you ready to step into the societal arena – arts, entertainment, sports – where personal values and virtue are either celebrated or distorted?</div>
                    </div>

                    <div class="spheres-tile-group media-border" @click="showCaption('media')">
                        <div class="sphere-spacer media-border"></div>
                        <div class="sphere-tile media-border"></div>
                        <div :class="['sphere-label', { active: activeCaption['media'] }]">Media</div>
                        <div :class="['sphere-caption', 'media-border', { active: activeCaption['media'] }]">Are you interested in truth and unbiased reporting of the news? Are you passionate about freedom of expression and addressing “cancel” culture in our society today?</div>
                    </div>

                    <div class="spheres-tile-group religion-border" @click="showCaption('religion')">
                        <div class="sphere-spacer religion-border"></div>
                        <div class="sphere-tile religion-border"></div>
                        <div :class="['sphere-label', { active: activeCaption['religion'] }]">Religion</div>
                        <div :class="['sphere-caption', 'religion-border', { active: activeCaption['religion'] }]">Are you passionate about making a difference in racial reconciliation, meeting social and physical needs outside the church, sending missionaries around the world and evangelizing the United States of America?</div>
                    </div>

                </div>

                <router-link to="/seven-spheres"><button class="button-light">Learn more about the Seven Spheres...</button></router-link>
            </div>
        </div>

    </div>
</template>

<script>
import Subscribe from '@/components/Subscribe.vue'
export default {
    name: 'home',
    components: {
        'subscribe': Subscribe
    },
    data() {
        return {
            loading: false,
            heroContent: [],
            vimeoURL: '',
            vimeoPlaying: false,
            showCaptions: false,
            activeCaption: {
                family: false,
                education: false,
                government: false,
                economics: false,
                celebration: false,
                media: false,
                religion: false
            }
        }
    },
    created() {
        if (!this.getAllowFullSite) {
            this.$router.push('/coming-soon')
        }
        this.apiCall({ destination: 'get_home_page_hero_content' }).then(obj => {
            this.loading = false
            if (obj.status == 'success') {
                this.heroContent = obj.hero_content
            }
        })
        this.vimeoURL = 'https://player.vimeo.com/video/601961671?autoplay=0&byline=0&title=0&controls=0&portrait=0&background=1&responsive=1'
        window.scrollTo(0, 0);
    },
    methods: {
        playVideo() {
            //if (this.vimeoPlaying) { return }
            this.vimeoPlaying = true
            let player = document.getElementById("player_iframe");
            let data = { method: "play" };
            player.contentWindow.postMessage(JSON.stringify(data), "*");
        },
        pauseVideo() {
            //if (!this.vimeoPlaying) { return }
            this.vimeoPlaying = false
            let player = document.getElementById("player_iframe");
            let data = { method: "pause" };
            player.contentWindow.postMessage(JSON.stringify(data), "*");
        },
        showCaption(which) {
            if (this.activeCaption[which]) {
                this.showCaptions = false
                this.activeCaption[which] = false
            } else {
                Object.keys(this.activeCaption).forEach(key => {
                    this.activeCaption[key] = (key == which)
                })
                this.showCaptions = true
            }
        }
    }
}
</script>

<style lang="scss">
@import '../styles/_colors.scss';
.hero-section {
    background-color: $darkergrey;
    h1 {
        width: 75%;
        margin: 0 auto;
        padding: 1em 0;
        @media all and (min-width: 768px) {
            padding: 2em;
        }
    }
	@media all and (min-width: 1200px) {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }
	@media all and (min-width: 1500px) {
        display: grid;
        grid-template-columns: 1fr 24em;
    }
    &.no_content { display: block; }
    .hero-video-container {
        width: 100%;
        height: auto;
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        iframe {
            left: 0; right: 0;
            top: 0; bottom: 0;
            height: 100%;
            width: 100%;
            position: absolute;
        }
        .hero-video-content {
            position: absolute;
            top: 10%;
            left: 0; right: 0;
            margin: 0 auto;
            color: #ffffff;
            img { display: none; }
            p {
                width: 90%;
                margin: 0 auto;
                padding: 0 0 0.5em 0;
                font-size: 0.9em;
                line-height: 1.33em;
                font-weight: bold;
            }
            .player-icon {
                font-size: 2em;
                opacity: 0.8;
            }
            .player-icon:hover {
                opacity: 1;
            }
        	@media all and (min-width: 768px) {
                top: 21%;
                p {
                    width: 21em;
                    margin: 1em auto;
                    font-size: 1.1em;
                    line-height: 1.33em;
                }
            }
	        @media all and (min-width: 1500px) {
                top: 25%;
                img { display: inline-block; width: 12em; }
                p {
                    width: 24em;
                    margin: 1.25em auto;
                    font-size: 1.25em;
                    line-height: 1.5em;
                }
        	}
        }
    }
    .hero-content-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 2em;
        color: #ffffff;
        text-align: left;
        padding: 1em;
        img { display: none; }
        h3 {
            color: $grey;
            margin: 0 0 0.25em 0;
            span { display: block; }
        }
        a { font-size: 0.9em; color: $church; }
    	@media all and (min-width: 1200px) {
            display: block;
            h3 {
                font-size: 1.25em;
                margin: 0 0 0.25em 0;
                span {
                    display: inline-block;
                    font-size: 0.8em;
                    padding: 0 0 0 1em;
                }
            }
            .spaced {
                margin: 1em 0 0.25em 0;
                padding: 1em 0 0 0;
                border-top: 1px solid $grey;
            }
            .spaced:first-of-type {
                margin: 0;
                padding: 0;
                border-top: 0 none;
            }
        }
    	@media all and (min-width: 1500px) {
            h3 {
                font-size: 1.5em;
                span {
                    display: block;
                    padding: 0;
                }
            }
            .spaced {
                margin: 1.5em 0 0.25em 0;
            }
        }
        .hero-content-tile {
            font-size: 0.8em;
            margin: 0 0 1em 0;
            img { max-width: 100%; }
            p { margin: 0 0 0.25em 0; }
            .caption { margin: 0 0 0 1em; color: $grey }
        	@media all and (min-width: 1200px) {
                font-size: 0.95em;
            }
        }
        .hero-content-tile:last-of-type {
            margin: 0;
            padding: 0;
            border-bottom: 0 none;
        }
    }
}

.section-light, .section-dark {
    @media all and (min-width: 768px) {
        padding: 3em 0;
    }
}

.vision-section {
    @media all and (min-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 2em;
    }
}

.quote-section, .covenant-section {
    .h1 { font-family: 'Bebas Neue', sans-serif; font-size: 1.15em; letter-spacing: 0.1em; }
    .button-light {
        font-size: 0.75em;
        margin: 2em 0 0 0;
    }
    @media all and (min-width: 768px) {
        font-size: 1.25em;
        max-width: 768px;
    }
}

.qoute-section-wrapper {
    background-image: url("~@/assets/heroimgs/heroimg6.jpg");
    background-size: cover;
    background-position: center bottom;
}

.prayer-section-wrapper {
    background-image: url("~@/assets/heroimgs/heroimg4.jpg");
    background-size: cover;
    background-position: center bottom;
}
.covenant-section-wrapper {
    background-image: url("~@/assets/quote-background1.png");
    background-size: cover;
    background-position: center bottom;
}
.unity-section {
    .icon {
        color: $darkgrey;
        font-size: 2em;
        transform: rotate(90deg);
        margin: 0 0 0.5em 0;
    }
    @media all and (min-width: 1200px) {
        .icon {
            transform: rotate(0deg);
            margin: 0;
        }
        display: grid;
        grid-template-columns: 1fr 2em 1fr 2em 1fr 2em 1fr;
        grid-gap: 2em;
    }
}

.spheres-section {
    overflow: hidden;
    h1 { margin: 0 0 0.75em 0; }
    .seven-spheres-grid {
        margin-top: 2em;
        margin-bottom: 2em;
        margin-left: 0em;
        padding: 1em 0;
        border-top: 1px solid $grey;
        border-bottom: 1px solid $grey;
        position: relative;
        transition: all 500ms;
        .spheres-tile-group {
            display: grid;
            grid-template-columns: 1em 2.5em 1fr;
            align-items: center;
            margin: 1em 0 1em 0;
            position: relative;
            cursor: pointer;
            .sphere-spacer {
                border-width: 3px;
                border-style: solid;
            }
            .sphere-tile {
                width: 1.5em;
                height: 1.5em;
                background-color: $white;
                border-radius: 0.75em;
                border-width: 9px;
                border-style: solid;
                margin: 0 0.5em;
            }
            .sphere-label {
                font-size: 1.33em;
                font-family: 'Bebas Neue', sans-serif;
                color: $darkgrey;
                text-align: left;
                transition: all 500ms;
                border-top-width: 6px;
                border-top-style: solid;
                border-top-color: $white;
                &.active {
                    border-top-color: inherit;
                }
            }
            .sphere-caption {
                position: absolute;
                z-index: 1000;
                opacity: 0;
                top: -1.5em;
                right: -18em;
                font-family: 'Uniform Condensed', sans-serif;
                font-size: 0.8em;
                width: 10.5em;
                border-left-width: 6px;
                border-left-style: solid;
                padding: 0 0 0 0.5em;
                text-align: left;
                background-color: $white;
                transition: all 500ms;
                @media all and (min-width: 768px) {
                    font-size: 0.9em;
                    width: 15em;
                    padding: 0 0 0 1em;
                }
                &.active {
                    right: 0em;
                    opacity: 1;
                }
            }
            @media all and (min-width: 768px) {
                grid-template-columns: 3em 2.5em 1fr;
                margin: 1em 0 1em 7em;
            }

        }
        &.active {
            margin-left: -2em;
            @media all and (min-width: 768px) {
                margin-left: -12em;
            }
        }

    }
    .spheres-tile-group:nth-child(n+5) {
        .sphere-caption {
            top: auto;
            bottom: 0.25em;
        }
    }
    .button-light {
        font-size: 0.75em;
    }
    @media all and (min-width: 768px) {
        font-size: 1.15em;
        max-width: 768px;
    }
}

</style>
