<template>
    <div class="subscribe">
        <div class="subscribe-form">
            <label for="name">Your name</label>
            <input type="text" ref="name" name="full_name" v-model="fullName" :readonly="saved">
            <label for="email">Your email address</label>
            <input type="text" ref="email" name="email_address" v-model="emailAddress" :readonly="saved">
            <label for="zip">Zip code (optional)</label>
            <input type="text" ref="zip" name="postal_code" v-model="postalCode" :readonly="saved">
            <label for="zip">Your interest</label>
            <select v-model="interestedIn">
                <option value="">Select...</option>
                <option v-for="(sphere, index) in spheres" :value="sphere.sphere_id">{{ sphere.description }}</option>
            </select>
            <label></label>
            <button :class="[buttonClass, { disabled: saving }]" @click="sendSubscription()">{{ buttonLabel || 'Subscribe' }}</button>
        </div>
        <p v-if="message">{{ message }}</p>
        <div class="note">Your zip code is optional but providing it will help us connect you with news and events in your area.<br>
        We promise to keep all of your information safe and private!  See our complete <router-link to="/privacy-policies">privacy policies here</router-link>.</div>
    </div>
</template>

<script>
export default {
    name: 'Subscribe',
    props: ['buttonLabel', 'buttonClass'],
    data() {
		return {
            spheres: [],
            fullName: '',
            emailAddress: '',
            postalCode: '',
            interestedIn: '',
            message: '',
            saving: false,
            saved: false
        }
    },
    created() {
        this.getInterests()
    },
    methods: {
        getInterests() {
            this.apiCall({ destination: 'get_list_spheres' }).then(obj => {
                if (obj.status == 'success') {
                    this.spheres = obj.spheres
                }
            })
        },
        sendSubscription() {
            if (this.saving || this.saved) {
                return false
            }
            if (!this.fullName) {
                this.message = 'Please enter your name.'
                return false
            }
            if (!this.emailAddress) {
                this.message = 'Please enter your email address.'
                return false
            }
            if (!this.isValidEmailAddress(this.emailAddress)) {
                this.message = 'Please enter a valid email address.'
                return false
            }
            this.saving = true
            this.message = 'Saving your info...'
            this.apiCall({ destination: 'save_frontend_form', data: { full_name: this.fullName, email_address: this.emailAddress, postal_code: this.postalCode, interest: this.interestedIn, form_type: 'WEBSITE_SUBSCRIBE_FORM' } }).then(obj => {
                if (obj.status == 'success') {
                    this.message = 'Your subscription info has been sent!'
                    this.saved = true
                } else {
                    this.message = obj.message
                    this.saving = false
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
    .subscribe-form {
        width: 75%;
        margin: 1em auto;
        grid-auto-flow: column;
        grid-template-rows: 1fr 1fr;
        grid-column-gap: 1em;
        label {
            font-size: 0.75em;
            text-align: left;
            align-self: end;
        }
        input {
            border-radius: 0;
            border: none;
        }
        @media all and (min-width: 768px) {
            display: grid;
        }
    }
</style>
