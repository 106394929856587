import Vue from 'vue'
import Vuex from 'vuex'
import { mapState, mapGetters, mapActions } from 'vuex'

let developmentEnvironment = (document.location.hostname.indexOf('localhost') > -1)
let sandboxEnvironment = (document.location.hostname.indexOf('sandbox') > -1)
let hostname = ''
if (developmentEnvironment) {
    hostname = 'gapapi.test'
} else if (sandboxEnvironment) {
    hostname = 'api.sandbox.gapmovement.com'
} else {
    hostname = 'api.gapmovement.com'
}
let apiURL = 'https://' + hostname + '/api.php'

// NOTE: this will prevent page navigation if token not present
// disable when we go live
let urlSearchParams = new URLSearchParams(window.location.search);
let params = Object.fromEntries(urlSearchParams.entries());
//let previewAllowed = developmentEnvironment ? true : params.token == 'zQvRX9bGpTmRrZPd3'

Vue.use(Vuex)

// instantiate store as a constant and export below to make globally available
const store = new Vuex.Store({
    state: {
        source: 'FRONTEND',
        apiEndpoint: apiURL,
        loading: false,
        allowFullSite: true,
        loginRequired: false,
        user: {},
        siteLandingPage: '/home' // NOTE: change to '/' when we go live
    },
    mutations: {
        UPDATE_LOADING(state, loading) {
			state.loading = loading
		},
		UPDATE_LOGIN_REQUIRED(state, value) {
			state.loginRequired = value
		},
		UPDATE_STATE(state, obj) {
			for (let prop in state) {
                if (Object.prototype.hasOwnProperty.call(obj, prop)) {
					state[prop] = obj[prop]
				}
			}
		},
        UPDATE_USER(state, user) {
			state.user = user
		}
    },
    getters: {
        getSiteLandingPage: state => state.siteLandingPage,
        getAllowFullSite: state => state.allowFullSite
    },
    actions: {
        apiCall({ state, commit }, parameters) {
            return new Promise((resolve, reject) => {
                // expected parameters: destination, data, headers, callback
                let url = (parameters.destination.indexOf('.') > 0) ? parameters.destination : state.apiEndpoint + '?action=' + parameters.destination
                if (!('data' in parameters)) { parameters.data = {} }
                // copy parameters to a new object so adding system-wide values won't update the original object
                let postData = JSON.parse(JSON.stringify(parameters));
                // add system-wide variables to the new object
                postData.data.source = state.source
                let defaultHeaders = {
                    method: 'post',
                    body: JSON.stringify(postData.data),
                    credentials: 'include'
                }
                let headers = postData.headers || defaultHeaders
                fetch(url, headers).then(function(response) {
                    return response.json()
                }).then(obj => {
                    commit('UPDATE_LOADING', false)
                    if (obj.error) {
                        console.log('error!')
                        obj.error.status = 'error'
                        resolve(obj.error)
                    } else {
                        if (obj.status == 'login_required' || (obj.user && !obj.user.user_name)) {
                            commit('UPDATE_LOGIN_REQUIRED', true)
                            if (document.location.pathname != '/') {
                                document.location.replace('/?return=' + document.location.pathname)
                            }
                        } else {
                            if (obj.user) {
                                sessionStorage.setItem('user_name', obj.user.user_name)
                                sessionStorage.setItem('user_type_code', obj.user.user_type_code)
                            }
                            commit('UPDATE_LOGIN_REQUIRED', false)
                        }
                        commit('UPDATE_STATE', obj)
                    }
                    resolve(obj)
                }).catch(error => {
                    console.log('error', error)
                    if (error == 'TypeError: Failed to fetch') {
                        // document.location.reload()
                    }
                    reject(error)
                })
            })
		}
    },
    modules: {
    }
})

export default store;

store.allGetters = Reflect.ownKeys(store.getters)
store.allActions = Reflect.ownKeys(store._actions)

Vue.mixin({
	methods: {
		...mapActions(store.allActions)
	},
	computed: {
		...mapGetters(store.allGetters)
	}
})
