<template>
    <div id="app">
      <template>
          <gap-header/>
          <router-view />
          <gap-footer/>
      </template>
    </div>
</template>

<script>
import '@/styles/_global.scss'
export default {
    name: 'App',
    data() {
        return {
            loading: false,
			hamburgerOn: false,
        }
    },
    created() {
        //console.log(this.getAllowFullSite) // set in store.js, prevents unauthorized preview
    }
}
</script>

<style lang="scss">
</style>
