import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAtom, faSun, faFire, faBible, faUserSecret,faSearch, faPhone, faEnvelope, faAt, faBars, faSpinner, faChevronRight, faInfoCircle, faPlay, faPause } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faUserSecret, faAtom, faSun, faFire, faBible, faSearch, faPhone, faEnvelope, faAt, faBars, faSpinner, faChevronRight, faInfoCircle, faPlay, faPause )

Vue.component('font-awesome-icon', FontAwesomeIcon)

import GAPHeader from '@/components/header'
Vue.component('gap-header', GAPHeader)
import GAPFooter from '@/components/footer'
Vue.component('gap-footer', GAPFooter)

Vue.config.productionTip = false

Vue.mixin({
    methods: {
        isValidPhoneNumber(phoneNumber) {
            var pattern = new RegExp(/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/);
            return pattern.test(phoneNumber);
        },
        isValidEmailAddress(emailAddress) {
            var pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
            return pattern.test(emailAddress);
        },
        isValidCreditCardNumber(value) {
            // Accept only digits, dashes or spaces
            if (!value || /[^0-9-\s]+/.test(value)) return false;
            // The Luhn Algorithm. It's so pretty.
            let nCheck = 0, bEven = false;
            value = value.replace(/\D/g, "");
            for (var n = value.length - 1; n >= 0; n--) {
            	var cDigit = value.charAt(n), nDigit = parseInt(cDigit, 10);
            	if (bEven && (nDigit *= 2) > 9) nDigit -= 9;
            	nCheck += nDigit;
            	bEven = !bEven;
            }
            return (nCheck % 10) == 0;
        }
    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
