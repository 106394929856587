<template>
    <footer>
        <div class="footer-logo-container">
            <img src="@/assets/gap-logo-small-dark.png">
        </div>
        <div class="footer-info">
            GAP's vision is to awaken the followers of Christ to restore intimacy with God, establish critical cultural transformation and create increasing Kingdom expansion, for generations to come.
        </div>
        <ul v-if="getAllowFullSite" class="footer-menu">
            <li>
                <router-link v-if="this.$route.path != '/'" to="/">Home</router-link>
                <a v-else @click="scrollToTop()">Home</a>
            </li>
            <li><router-link to="/about">About GAP</router-link></li>
            <li><router-link to="/seven-spheres">Seven Spheres</router-link></li>
            <li><router-link to="/the-prayer-room">The Prayer Room</router-link></li>
            <li><router-link to="/urgency-of-history">The Urgency of History</router-link></li>
            <li><router-link to="/four-crucial-values">Four Crucial Values</router-link></li>
        </ul>
        <ul v-if="getAllowFullSite" class="footer-menu">
            <li><router-link to="/upcoming-events">Upcoming Events</router-link></li>
            <li><router-link to="/donate">Donate to GAP</router-link></li>
            <li>
                <router-link to="/contact">Contact GAP
                <div class="social">
                    <!-- <font-awesome-icon icon="phone" class="icon"/> -->
                    <font-awesome-icon icon="envelope" class="icon"/>
                    <font-awesome-icon icon="at" class="icon"/>
                </div>
                </router-link>
            </li>
            <li><router-link to="/privacy-policies">Privacy Policies</router-link></li>
        </ul>
    </footer>
</template>

<script>
export default {
    name: 'gap-footer',
    data() {
        return {
            navigationAllowed: false
        }
    },
    created() {
        if (this.$route.query.token == 'RvCP6VZGntmRrvP') {
            this.navigationAllowed = true
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }
    }
}
</script>
