<template>
    <header>

        <router-link v-if="getAllowFullSite" to="/"><img src="@/assets/gap-logo-small.png" @click="showMobileMenu = false"></router-link>
        <div v-else><img src="@/assets/gap-logo-small.png"></div>

        <div v-if="getAllowFullSite" id="nav">
            <div id="nav-meta">
                <ul>
                    <li><router-link to="/contact">Contact</router-link></li>
                    <li><router-link to="/donate">Donate</router-link></li>
                </ul>
            </div>
            <ul>
                <li><router-link to="/about">About GAP</router-link></li>
                <li><router-link to="/seven-spheres">Seven Spheres</router-link></li>
                <li><router-link to="/the-prayer-room">The Prayer Room</router-link></li>
                <li><router-link to="/upcoming-events">Upcoming Events</router-link></li>
            </ul>
        </div>

        <!-- <font-awesome-icon icon="search" class="icon search"></font-awesome-icon> -->
        <!-- <button class="button-dark">Log In</button> -->

        <div class="hamburger-icon" @click="showMobileMenu = !showMobileMenu">
            <font-awesome-icon icon="bars" class="icon hamburger"></font-awesome-icon>
        </div>

        <div v-if="showMobileMenu" id="nav-mobile" @click="showMobileMenu = false">
            <ul>
                <li><router-link to="/about">About GAP</router-link></li>
                <li><router-link to="/seven-spheres">Seven Spheres</router-link></li>
                <li><router-link to="/the-prayer-room">The Prayer Room</router-link></li>
                <li><router-link to="/upcoming-events">Upcoming Events</router-link></li>
                <li><router-link to="/contact">Contact</router-link></li>
                <li><router-link to="/donate">Donate</router-link></li>
            </ul>
        </div>

    </header>
</template>

<script>
export default {
    name: 'gap-header',
    data() {
        return {
            navigationAllowed: false,
            showMobileMenu: false
        }
    },
    created() {
        if (this.$route.query.token == 'RvCP6VZGntmRrvP') {
            this.navigationAllowed = true
        }
    }
}
</script>
